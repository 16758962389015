<template>
  <ul
    :data-active-marker="viewTime?.get(dial)"
    :style="`--dial-selector-width: ${width}px`"
    class="dial-selector"
    ref="dialEl"
    v-class-mod:dial-selector="dial"
  >
    <li
      v-for="m in markers"
      :tabindex="isDisabled(Number(m)) ? -1 : 0"
      class="dial-selector__marker"
      @keydown.enter="submit(Number(m))"
      ref="markerEl"
      :key="m"
      v-class-mod:dial-selector__marker="{ isDisabled: isDisabled(Number(m)) }"
    >
      {{ m }}
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";

import { DateTime, getTime } from "@horizon56/time";
import { delayFn, eventHandler, isHtmlElem, isNumber, throttle, zeroPrefix } from "@horizon56/utils";

export type Dial = "hours" | "minutes" | "seconds";

const props = defineProps<{
  dial: Dial;
  time?: DateTime;
  min?: DateTime;
  max?: DateTime;
}>();

const emit = defineEmits<{
  (e: "update"): void;
  (e: "update:date", a: DateTime): void;
  (e: "update:dial", a: Dial): void;
}>();

const dialEl = ref<HTMLElement>();
const markerEl = ref<HTMLElement[]>();

const width = ref(-1);
const viewTime = ref(props.time ?? getTime(true));

const isDisabled = (marker: number) => {
  if (props.min && viewTime.value?.isSame(props.min, "date")) {
    if (props.dial === "hours" && marker < props.min.get("hours")) {
      return true;
    } else if (
      viewTime.value?.isSame(props.min, "hour") &&
      props.dial === "minutes" &&
      marker < props.min.get("minutes")
    ) {
      return true;
    }
  }

  if (props.max && viewTime.value?.isSame(props.max, "date")) {
    if (props.dial === "hours" && marker > props.max.get("hours")) {
      return true;
    } else if (
      viewTime.value?.isSame(props.max, "hour") &&
      props.dial === "minutes" &&
      marker > props.max.get("minutes")
    ) {
      return true;
    }
  }

  return false;
};

const resetViewTime = () => {
  updateDialForClosestMarker.cancel();
  viewTime.value = props.time ?? getTime(true);
};

const updateDialForClosestMarker = throttle((e: MouseEvent | TouchEvent) => {
  e.stopPropagation();
  const xPos = e.type === "touchmove" ? (e as TouchEvent).touches[0].clientX : (e as MouseEvent).x;
  const yPos = e.type === "touchmove" ? (e as TouchEvent).touches[0].clientX : (e as MouseEvent).y;

  let newKey = 0;
  let closestDistance;

  for (const [key, marker] of (markerEl.value || []).entries()) {
    if (marker.classList.contains("dial-selector__marker--isDisabled")) {
      continue;
    }
    const rect = marker.getBoundingClientRect();
    const xElemPos = rect.left + rect.width / 2;
    const yElemPos = rect.top + rect.height / 2;
    const xDistance = xPos - xElemPos;
    const yDistance = yPos - yElemPos;
    const distance = Math.sqrt(xDistance * xDistance + yDistance * yDistance);
    if (closestDistance === undefined || distance < closestDistance) {
      newKey = key;
      closestDistance = distance;
    }
  }

  viewTime.value = viewTime.value?.set(props.dial, newKey);
}, 100);

const updateDial = (newKey: number) => {
  viewTime.value = viewTime.value?.set(props.dial, newKey);
};

const submit = (e: MouseEvent | TouchEvent | number) => {
  if (isNumber(e)) {
    updateDial(e);
  } else {
    e.stopPropagation();
    updateDialForClosestMarker.cancel();
    updateDialForClosestMarker(e);
  }
  if ((!props.time && viewTime.value) || (props.time && viewTime.value && +props.time !== +viewTime.value)) {
    emit("update:date", viewTime.value);
  }
  if (props.dial === "hours") {
    emit("update:dial", "minutes");
  }
  emit("update");
};

const { add, remove } = eventHandler(
  () => dialEl.value,
  [
    ["mousemove", updateDialForClosestMarker],
    ["touchmove", updateDialForClosestMarker],
    ["mouseleave", resetViewTime],
    ["click", submit],
  ],
);

add();

const markers = computed(() => {
  const _markers = ["00"];
  for (let i = 1; i <= (props.dial === "hours" ? 23 : 59); i++) {
    _markers.push(zeroPrefix(i));
  }
  return _markers;
});

const setFocus = async () => {
  await delayFn(() => !!markerEl.value?.[viewTime.value?.get(props.dial) ?? 0]);
  const elem = markerEl.value?.[viewTime.value?.get(props.dial) ?? 0];
  if (isHtmlElem(elem)) {
    elem.focus();
  }
};

onMounted(async () => {
  await delayFn(() => isHtmlElem(dialEl.value));
  if (isHtmlElem(dialEl.value)) {
    width.value = dialEl.value.getBoundingClientRect()?.width;
  }
});

onBeforeUnmount(remove);

watch(
  () => props.time,
  () => (viewTime.value = props.time ?? getTime(true)),
);

watch(
  () => props.dial,
  (e, o) => {
    if (e === "minutes" && o === "hours") {
      setFocus();
    }
  },
);

defineExpose({ setFocus });
</script>

<style lang="scss">
.dial-selector {
  $block: &;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: var(--dial-selector-width);
  background: var(--black-20);
  border-radius: 50%;
  user-select: none;
  cursor: pointer;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    display: block;
    left: calc(50% - 0.25em);
    top: calc(50% - 0.25em);
    height: 0.5em;
    width: 0.5em;
    border-radius: 50%;
    background: var(--green-500);
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 50%;
    right: 50%;
    height: 42.5%;
    border: 1px solid var(--green-500);
    transform-origin: bottom right;
  }
  &__sizer {
    width: 100%;
    padding-bottom: 100%;
    display: flex;
  }
  &__marker {
    position: absolute;
    font-size: 0.875em;
    z-index: 99;
    border-radius: 50%;
    background: transparent;
    width: 2em;
    height: 2em;
    left: calc(50% - 1em);
    top: calc(50% - 1em);
    line-height: 2em;
    text-align: center;
    transform: translate(-50%, -50%);
    &--isDisabled {
      opacity: 0.2;
      pointer-events: none;
    }
    @include focus-outline;
    &:focus-visible {
      outline-offset: 0;
    }
  }
  &--hours {
    @for $i from 1 through 24 {
      $r: 30 * ($i - 1);
      $offset: calc(var(--dial-selector-width) / 2 - 1.5em);
      @if ($i > 12) {
        $offset: calc(var(--dial-selector-width) / 2.75 - 1.5em);
      }
      #{$block}__marker:nth-child(#{$i}) {
        transform: rotate(-90deg) rotate($r * 1deg) translateX($offset) rotate(90deg) rotate($r * -1deg);
      }

      &[data-active-marker="#{($i - 1)}"] #{$block}__marker:nth-child(#{$i}) {
        background: var(--green-500);
        color: var(--static-white);
      }
      &[data-active-marker="#{$i - 1}"]:after {
        transform: rotate($r * 1deg) translateX(50%);
        @if ($i > 12) {
          height: 30%;
        }
      }
    }
  }
  &--seconds,
  &--minutes {
    #{$block}__marker:not(:nth-child(5n + 1)) {
      font-size: 0;
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        height: 0.25em;
        width: 0.25em;
        border-radius: 50%;
        background: var(--text);
        transform: translate(-50%, -50%);
        opacity: 0;
      }
    }
    @for $i from 1 through 60 {
      $r: 6 * ($i - 1);
      $offset: calc(var(--dial-selector-width) / 2 - 1.5em);
      #{$block}__marker:nth-child(#{$i}) {
        transform: rotate(-90deg) rotate($r * 1deg) translateX($offset) rotate(90deg) rotate($r * -1deg);
      }
      &[data-active-marker="#{($i - 1)}"] #{$block}__marker:nth-child(#{$i}) {
        background: var(--green-500);
        color: var(--static-white);
        font-size: inherit;
        &:after {
          opacity: 1;
        }
      }
      &[data-active-marker="#{$i - 1}"]:after {
        transform: rotate($r * 1deg) translateX(50%);
      }
    }
  }
}
</style>
